<template>
  <div>
      <div v-if="layout === 'vertical'">
        <span v-if="address.name" class="mb-3 row">
          {{ address.name }}
        </span>
        <span v-if="address.street" class="mb-3 row">
          {{ address.street }}
        </span>
        <span v-if="address.city" class="mb-3 row">
          {{ address.city }}
        </span>
        <span v-if="address.province" class="mb-3 row">
          {{ address.province }}
        </span>
        <span v-if="address.country" class="mb-3 row">
          {{ address.country }}
        </span>
      </div>
      <div v-else>
        <template v-if="address.name" class="mb-3 row">
          {{ address.name }},
        </template>
        <template v-if="address.street" class="mb-3">
            {{ address.street }},
        </template>
        <template v-if="address.city" class="mb-3">
            {{ address.city }},
        </template>
        <template v-if="address.province" class="mb-3">
            {{ address.province }},
        </template>
        <template v-if="address.country" class="mb-3">
            {{ address.country }}
        </template>
      </div>
  </div>
</template>

<script>
export default {
  name: "AddressDetail",
  props: {
    address: Object,
    layout: String
  }
}
</script>

<style scoped>

</style>