<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Create Prescription Order</h3>
          <div class="justify-content-between">
            <button v-on:click="savePrescriptionOrder()" :disabled="total === 0" class="ps-btn btn-sm">
              <i class="icon-archive"></i>
              {{ isLoading ? 'Saving...' : 'Save Order' }}
              <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
              <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
            </button>
          </div>
        </div>
        <div class="row mt-3 mb-3" v-if="message">
          <div class="col-12">
            <small class="text-danger" v-if="isError">{{ message }}</small>
            <small class="text-success" v-else>{{ message }}</small>
          </div>
        </div>
        <div class="row">

          <div class="col-4 col-xs-4 from">
            <p class="lead margin-bottom">To: </p>
            <div class="form-group">
              <label>Patient Name</label>
              <input class="form-control" type="text" v-model="shipping.name" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <label>Email Address<sup>*</sup> </label>
              <div class="form-group__content">
                <input class="form-control" v-model="shipping.email" type="email" />
              </div>
            </div>
            <div class="form-group">
              <label>Phone<sup>*</sup> </label>
              <div class="form-group__content">
                <input class="form-control" v-model="shipping.phone_number" type="text" />
              </div>
            </div>
            <div class="form-group">
              <label>Address<sup>*</sup> </label>
              <div class="form-group__content">
                <input class="form-control" v-model="shipping.street"  type="text" />
              </div>
            </div>
          </div>

          <div class="col-4 col-xs-4 to">&nbsp;</div>

          <div class="col-4 col-xs-4 text-right payment-details">
            <p class="lead margin-bottom payment-info">Pickup/Delivery Branch</p>
            <div class="form-group">
              <label class="form-label" for="pickup_branch">Branches</label>
              <select class="form-control" name="pickup_branch" id="pickup_branch" v-model="pickUpBranchId" v-on:change="showBranchAddress">
                <option :value="shopAddress.id" v-for="shopAddress in shopAddresses">{{ shopAddress.name }}</option>
              </select>
            </div>
            <div class="form-check mb-4" v-if="pickUpBranch && pickUpBranchId">
                <AddressDetail :address="pickUpBranch" :layout="'vertical'"></AddressDetail>
            </div>
          </div>

        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
            <tr>
              <th width="35%">Prescription</th>
              <th class="text-right">Quantity</th>
              <th class="text-right">Price ($)</th>
              <th class="text-right">Repeats</th>
              <th class="text-right">Total ($)</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
                <order-line
                    @add-prescription-line="addPrescriptionLine"
                ></order-line>
                <tr v-for="(line, index) in lines" :key="index">
                  <td>{{ line.prescription.code }} - {{ line.prescription.name }}</td>
                  <td class="text-right">{{ line.quantity }}</td>
                  <td class="text-right">{{ line.price }}</td>
                  <td class="text-right">{{ line.repeats }}</td>
                  <td class="text-right">{{ line.total }}</td>
                  <td class="text-right">
                    <a href="#" class="btn btn-danger" @click="deleteLine(index)" style="font-size: 14px">
                      <i v-if="!isRemoving" class="icon-minus"></i>
                      {{ isRemoving ? "Deleting..." : "" }}
                      <i v-if="!isRemoving" class="icon icon-right-arrow ml-1"></i>
                      <div v-if="isRemoving" class="spinner-border text-light" role="status"></div>
                    </a>
                  </td>
                </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td class="text-right"><strong>{{ total | toCurrency }}</strong></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderLine from "./OrderLine";
import axios from "axios";
import {mapState} from "vuex";
import AddressDetail from "../../../../../components/AddressDetail";
export default {
  name: "Create",
  components: {AddressDetail, OrderLine},
  data() {
    return {
      total: 0,
      lines: [],
      isRemoving: false,
      isLoading: false,
      isError: false,
      message: null,
      shipping: {
        street: '',
        country: '',
        city: '',
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      },
      shopAddresses: [],
      pickUpBranchId: null,
      pickUpBranch: [],
    }
  },
  mounted() {
    this.getShopAddress();
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
      auth: state => state.auth
    }),
  },
  methods: {
    showBranchAddress() {
      this.pickUpBranch = this.shopAddresses.find(shopAddress => shopAddress.id === this.pickUpBranchId);
    },

    getShopAddress() {
      axios.get('address?shop=' + this.auth.shop.id).then((response) => {
        this.shopAddresses = response.data
        if (this.shopAddresses.length > 0) {
          this.pickUpBranch = this.shopAddresses[0]
          this.pickUpBranchId = this.pickUpBranch.id;
          this.showBranchAddress()
        }
      })
    },

    addPrescriptionLine(payload) {
      this.lines.push(payload)
      this.calculateTotal();
    },

    deleteLine(prescriptionIndex) {
      let lines = []
      this.lines.forEach(function(line, index){
        if (index !== prescriptionIndex) {
          lines.push(line);
        }
      });
      this.lines = lines;
      this.calculateTotal();
    },
    calculateTotal() {
      let thisTotal = 0
      this.lines.forEach(function(line, index){
        thisTotal += line.total;
      });
      this.total = thisTotal;
    },
    savePrescriptionOrder() {
      this.isLoading = true;
      axios.post('orders/prescriptions/create', {
        total_amount: this.total,
        order_items: this.lines,
        pick_up_address_id: this.pickUpBranchId,
        shipping: this.shipping
      }).then((response) => {
        this.isLoading = false;
        if (response.status === 200 || response.status === 201) {
          this.message = 'Prescription order successfully created'
          this.$router.push({name: 'merchant_prescription_orders'});
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.message = "Prescription order could not be created. Please enter all required fields.";
          this.errors = error.response.data.errors
        } else {
          this.message = error.backendErrors[0]
        }
        this.isError = true
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>